<script>
import AppIcon from "@/features/general/Icons";

export default {
  name: "AppInputGroup",

  components: {
    AppIcon,
  },

  props: {
    /**
     * Identificador para a o "link" das labels
     */
    identifier: String,

    /**
     * Valor da model
     */
    value: String,

    /**
     * Label flutuante
     */
    label: String,

    /**
     * Loading do elemento pai
     */
    loading: Boolean,

    /**
     * Estado do input
     * initial/valid/invalid
     */
    state: {
      type: [String, Boolean],
      default: null,
    },

    /**
     * Icone para ser utilizado no <app-icon> da aplicação
     */
    icon: {
      type: Object,
      required: true,
      default() {
        return {
          prefix: "fa",
          name: "list-ul",
          flip: true,
        };
      },
    },
    extraIcon: {
      type: Object,
      default() {
        return {
          prefix: "",
          name: "",
        };
      },
    },
  },
  data() {
    return {
      group_classes: {
        initial: "",
        valid: "",
        invalid: "app__input-group--is-invalid",
        pending: "",
      },
    };
  },

  computed: {
    /**
     * Define o estado real do input baseado no valor da prop
     * para manter o padrão esta regra á a mesma dos inputs do plugin bootstrap-vue
     * https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/mixins/form-state.js
     * @return string
     */
    computedState() {
      const state = this.state;

      if (state === "") {
        return "initial";
      } else if (state === true || state === "valid") {
        return "valid";
      } else if (state === false || state === "invalid") {
        return "invalid";
      } else if (state === "pending") {
        return "pending";
      }

      return "initial";
    },

    /**
     * Retorna a classe do input-group baseado no state
     * @return string
     */
    groupClass() {
      return this.group_classes[this.computedState];
    },

    /**
     * Verifica se existe um icone extra para ser exibido
     * @return {boolean}
     */
    hasExtraIcon() {
      return this.extraIcon.prefix && this.extraIcon.name ? true : false;
    },
  },
};
</script>

<template>
  <fieldset
    :id="`app__input-group-${identifier}`"
    class="app__input-group"
    :class="groupClass"
  >
    <span
      :class="{
        'form-control-sucess': this.computedState === 'valid' && value,
        'form-control-error': this.computedState === 'invalid',
      }"
    >
      <slot name="input" />

      <label
        class="app__input-group__label"
        :for="this.identifier"
        v-if="!loading"
      >
        <app-icon
          class="app__input-group__icon-identifier"
          :flip="this.icon.flip"
          :prefix="this.icon.prefix"
          :name="this.icon.name"
        >
        </app-icon>
        <app-icon
          class="app__input-group__icon-invalid"
          prefix="fa"
          name="times"
          v-show="this.computedState === 'invalid'"
        >
        </app-icon>
        <app-icon
          class="app__input-group__icon-valid"
          prefix="fa"
          name="check"
          v-show="this.computedState === 'valid' && value"
        >
        </app-icon>
        <app-icon
          class="app__input-group__icon-sync-alt app__icon--infinite-rotate"
          prefix="fa"
          name="sync-alt"
          v-show="this.computedState === 'pending'"
        >
        </app-icon>
        <app-icon
          v-if="this.hasExtraIcon"
          class="app__input-group__icon-extra"
          :prefix="this.extraIcon.prefix"
          :name="this.extraIcon.name"
          v-show="this.computedState === 'initial'"
        >
        </app-icon>
        <span
          class="app__input-group__float-label app__animated"
          :class="
            this.value
              ? 'app__animated--fade-in-up'
              : 'app__animated--fade-out-down'
          "
        >
          {{ this.label }}
        </span>
      </label>
    </span>

    <slot name="feedbacks" />
    <slot name="feedbacks--backend" />
  </fieldset>
</template>
